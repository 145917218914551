export const environment = {
    production: false,
    version: '1.2.0.0-20240328-dev',
    test: 'develop',
    googleAnalytics: 'G-32HDJ1NRMT',
    googleAnalyticsTechnical: 'G-JX2GHBQLYG',
    rol: '',
    expressUserData: [],
    multiCountryData: [],
    TelemercadeoCountryData: [],
    basePath: '/',
    apigoogle: 'AIzaSyBLMW-yZQI4A_mnrDCugdzhMl1RPLjrJ4w',
    linkEntregaPage: 'https://linkdepago.forzadelivery.com',
    apiLinkEntrega: 'https://backendlinkpago.forzadelivery.com/api/V1',
    apiBackApp: 'https://backendlinkpago.forzadelivery.com/app/api/V1',
    linkQrAfiliatePage: 'https://qa.portal.forzadelivery.com/clubForza/',
    portalRoute: 'https://develop.portal.forzadelivery.com',
};

export enum Constants {
    api = 'https://develop.portal.forzadelivery.com/fd/Home.aspx/API',
    sesionTime = 900000, // 15 * 60 * 1000 = 900000 milisegundos = 15 minutos
    sesionTimeExpress = 32400000, // 9 horas * 60 minutos  * 60 segundos * 1000 milisegundos = 32400000
}
