<!-- eslint-disable prettier/prettier -->
<div
    class="container-fluid">
    <div class="col-12">
        <div class="row">
            <div class="col-12 title mb-2 mb-md-4 mt-2 ml-3 text-black">
                CARRITO DE COMPRAS
            </div>
        </div>
    </div>
    <div class="row mx-0">
        <div class="col-xs-12 col-lg-4 pr-lg-0">
            <div class="card mb-2 shadow">
                <div class="card-header">
                    <div class="card-title text-h1 text-center text-black">
                        <Strong>Detalle de la compra</Strong>
                    </div>
                </div>
                <div class="card-body">
                    <div
                        class="row justify-content-between mx-3 bb-2 my-1"
                        *ngIf="numberCard">
                        <div class="col-auto px-0">
                            <label class="text-h3 mb-0"> Tarjeta </label>
                        </div>
                        <div class="col-auto px-0">
                            <label class="text-h4 mb-0">
                                <strong>{{ numberCard }}</strong>
                            </label>
                        </div>
                    </div>
                    <div
                        class="row justify-content-between mx-3 bb-2 my-1"
                        *ngIf="billingData">
                        <div class="col-auto px-0">
                            <label class="text-h3 mb-0"> {{taxType}} </label>
                        </div>
                        <div class="col-auto px-0">
                            <label class="text-h4 mb-0">
                                <strong>{{ billingData?.TaxId }}</strong>
                            </label>
                        </div>
                    </div>
                    <div class="row justify-content-between mx-3 bb-2 my-1">
                        <div class="col-auto px-0">
                            <label class="text-h3 mb-0">
                                Cantidad de productos
                            </label>
                        </div>
                        <div class="col-auto px-0">
                            <label class="text-h2 mb-0">
                                {{ detailCart?.listProductsOfCart?.length }}
                            </label>
                        </div>
                    </div>
                    <div class="row justify-content-between mx-3 bb-2 my-1" *ngIf="detailCart?.discountTotal > 0">
                        <div class="col-auto px-0">
                            <label class="text-h3 mb-0"> Descuento </label>
                        </div>
                        <div class="col-auto px-0">
                            <label class="text-h2 mb-0">
                                {{ amountTotal(detailCart?.discountTotal) }}
                            </label>
                        </div>
                    </div>
                    <div class="row justify-content-between mx-3 bb-2 my-1">
                        <div class="col-auto px-0">
                            <label class="text-h3 mb-0"
                                ><strong>Total a pagar</strong>
                            </label>
                        </div>
                        <div class="col-auto px-0">
                            <label class="text-h2 mb-0">
                                <strong>{{
                                    amountTotal(detailCart?.amountTotal)
                                }}</strong>
                            </label>
                        </div>
                    </div>
                    <div class="row justify-content-between mx-0 bb-2 my-2 mx-3" *ngIf="userRol != 'Ventas telemercadeo'">
                        <div class="form-check">
                            <input
                            [disabled]="showPayBtn()" 
                            [(ngModel)]="termsAndConditions"
                            (click)="
                                     showModaltermAndCondition()
                            "
                                class="form-check-input radio-button cursor-pointer"
                                type="checkbox"
                                value="false"
                                id="termAndCondition"/>
                            <label
                                class="form-check-label ml-3 text-h2 cursor-pointer"
                                for="termAndCondition">
                                Estoy de acuerdo con los términos y condiciones.
                            </label>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12 d-flex justify-content-center">
                            <button
                                class="btn btn-warning text-h3 text-white w-100 pay-cart"
                                (click)="onClickPay()"
                                *ngIf="
                                    !showPayBtn()
                                "
                                [ngClass]="{'btn-disabled-gray': disableButton()}"
                                [disabled]="disableButton()">
                                Pagar Carrito
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pt-2 pb-4 assistance-text" *ngIf="userRol != 'Ventas telemercadeo'">
                ¿Requieres asistencia con tu compra? Comunícate con nosotros al
                <a class="m-0" href="tel:{{pbx}}">
                    <strong class="m-0 assistance-text"> PBX: {{pbx}} </strong>
                </a>
                y con gusto te apoyaremos.
            </div>
        </div>
        <div class="col-xs-12 col-lg-8 pr-lg-0">
            <accordion [closeOthers]="false" *ngIf="userRol == 'Ventas telemercadeo'">            
                <accordion-group     
                    panelClass="b0 mb-2"
                    class="shadow"
                    #customerInformation
                    [formGroup]="telemarketingPaymentForm"
                    isAnimated="true"
                    [isDisabled]="true">
                    <div accordion-heading>
                        <span class="card-title text-h1">
                            <strong> 1. Información del cliente </strong>
                        </span>
                    </div>
                    <div>
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <app-input
                                    title="Correo del usuario"
                                    [required]="true"
                                    [validators]="validatorsCustom.email"
                                    suffix="bi bi-search"
                                    (keyup.enter)="searchCustomer()"
                                    (onClickIcon)="searchCustomer()"
                                    [disabled]="LoaderInfoCustomer"
                                    (formReady)="addForm('emailUser', $event)"
                                    [loading]="LoaderInfoCustomer"
                                    (onChangeInput)="changeEmail()"></app-input>
                            </div>
                            <div
                                class="col-12 col-lg-6 mt-2 mt-lg-0"
                                [ngClass]="{'mb-2 mb-lg-0': noRegisterUser}"
                                *ngIf="!LoaderInfoCustomer">
                                <ng-container *ngFor="let item of dataCustomer">
                                    <div
                                        class="row"
                                        *ngIf="item.type == 'neutral'">
                                        <div class="col-12">
                                            <label class="text-h3">
                                                {{ item.text }}
                                            </label>
                                        </div>
                                        <div class="col-12">
                                            <label class="text-h4">
                                                {{ item.value }}
                                            </label>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="noRegisterUser">
                                    <div class="h-100 d-flex align-items-center mt-2 mt-lg-0 text-h3 container-message-no-user">
                                        Usuario no registrado, por favor ingrese datos de facturación
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </accordion-group>
            </accordion>   
            <accordion [closeOthers]="true">
                <accordion-group
                    panelClass="b0 mb-2"
                    class="shadow"
                    #billingInformation
                    isAnimated="true">
                    <div accordion-heading>
                        <span class="card-title text-h1">
                            <strong> {{userRol == 'Ventas telemercadeo' ? '2.' : '1.'}} Información de Facturación </strong>
                        </span>
                    </div>
                    <div>
                        <div
                            class="row px-4"
                            *ngIf="userLogged && userRol == 'Individual' || customerSelect"
                            [formGroup]="checkoutForm">
                            <div class="col-6">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        id="CF"
                                        type="radio"
                                        name="billingOption"
                                        value="CF"
                                        formControlName="billingOption"
                                        (click)="selectBilling('CF')" />
                                    <label
                                        class="form-check-label text-h4 mt-1 mb-0"
                                        for="CF">
                                        CF
                                    </label>
                                    <br />
                                    <label class="text-referencia">
                                        Consumidor final
                                    </label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-check">
                                    <input
                                        class="form form-check-input"
                                        id="FAV"
                                        type="radio"
                                        name="billingOption"
                                        value="FAV"
                                        formControlName="billingOption"
                                        (click)="selectBilling('FAV')" />
                                    <label
                                        class="form-check-label text-h4 mt-1 mb-0"
                                        for="FAV">
                                        Facturación favorita
                                    </label>
                                    <br />
                                    <label class="text-referencia">
                                        Datos de facturación favorita
                                    </label>
                                </div>
                                <label
                                [ngClass]="{
                                    'text-muted': showPayBtn()
                                }"
                                    class="cursor-pointer text-h3"
                                    (click)="onClickAddBilling()">
                                    <i class="bi bi-plus-circle"></i>
                                    Agregar método de facturación
                                </label>
                            </div>
                        </div>
                        <div
                            class="row px-4"
                            *ngIf="!userLogged || userRol != 'Individual' && !customerSelect"
                            [formGroup]="billingFormManualy">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-sm-2">
                                        {{this.taxType}}<span class="text-danger">*</span>
                                    </div>
                                    <div class="col-12 col-sm-10 mb-2 mb-sm-3">
                                        <app-input
                                            placeholder="Ingrese {{this.taxType}}"
                                            [validators]="this.taxType === 'NIT' ? validatorsCustom.NIT : validatorsCustom.RTN"
                                            (formReady)="addForm('NIT', $event)"
                                            (onChangeInput)="addManualNIT()">
                                        </app-input>
                                    </div>
                                    <div class="col-12 col-sm-2">
                                        Nombre<span class="text-danger">*</span>
                                    </div>
                                    <div class="col-12 col-sm-10 mb-2 mb-sm-3">
                                        <app-input
                                            placeholder="Ingrese un nombre"
                                            [validators]="validatorsCustom.name"
                                            (formReady)="
                                                addForm('name', $event)
                                            ">
                                        </app-input>
                                    </div>  
                                    <div class="col-12 col-sm-2">
                                        Dirección<span class="text-danger"
                                            >*</span
                                        >
                                    </div>
                                    <div class="col-12 col-sm-10 mb-2 mb-sm-3">
                                        <app-input
                                            placeholder="Ingrese una dirección"
                                            [validators]="
                                                validatorsCustom.address
                                            "
                                            (formReady)="
                                                addForm('address', $event)
                                            ">
                                        </app-input>
                                    </div>
                                    <div class="col-12 col-sm-2">
                                        Correo<span class="text-danger">*</span>
                                    </div>
                                    <div class="col-12 col-sm-10">
                                        <app-input
                                            placeholder="Ingrese correo electrónico"
                                            [validators]="
                                                validatorsCustom.email
                                            "
                                            (onChangeInput)="onChangeEmail($event)"
                                            (formReady)="
                                                addForm('email', $event)
                                            ">
                                        </app-input>
                                    </div>
                                    <div class="col-12 col-sm-2 mt-2 mt-sm-3" *ngIf="!userLogged">
                                        Teléfono<span class="text-danger">*</span>
                                    </div>
                                    <div class="col-12 col-sm-10 mt-2 mt-sm-3" *ngIf="!userLogged">
                                        <!-- <app-input
                                            placeholder="Ingrese número de teléfono"
                                            (formReady)="
                                                addForm('phone', $event)
                                            "
                                            [required]="!userLogged"
                                            [maxLength]="8"
                                            [minLength]="8"">
                                        </app-input> -->
                                        <app-inputv2
                                            [isPhone]="true"
                                            [required]="!userLogged"
                                            [prefixPhone]="prefixPhone"
                                            formControlName="phone"
                                            [control]="billingFormManualy.controls.phone"
                                            [type]="'tel'"
                                            [style]="1"
                                            [showTextLabel]='false'
                                            [placeholder]="'Ingrese número de teléfono'"
                                            [maxLength]="8"
                                            [minLength]="8"
                                            [disabled]="disabledPhone"
                                            (sendPrefixPhone)="getPrefixPhone($event)">
                                        </app-inputv2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="row mx-0 mt-4 justify-content-between container-data-nit"
                            *ngIf="billingData">
                            <div class="col-auto">
                                <label class="text-h2"> Nombre factura </label>
                                <br />
                                <label class="text-h3">
                                    <i class="bi bi-person"></i>
                                    {{ billingData?.Name }}
                                </label>
                            </div>
                            <div class="col-auto">
                                <label class="text-h2"> Dirección </label>
                                <br />
                                <label class="text-h3">
                                    <i class="bi bi-geo-alt"></i>
                                    {{ billingData?.Address }}
                                </label>
                            </div>
                            <div class="col-auto">
                                <label class="text-h2"> Número de {{this.taxType}}</label>
                                <br />
                                <label class="text-h3">
                                    <i class="bi bi-card-text"></i>
                                    {{ billingData?.TaxId }}
                                </label>
                            </div>
                        </div>
                        <div class="w-100 mt-3 d-flex justify-content-end pr-4" *ngIf="userRol != 'Individual' && !customerSelect">
                            <div class="w-auto">
                                <button
                                    [disabled]="disabledBtnNext(1)"
                                    [ngClass]="{'btn-disabled-gray': disabledBtnNext(1)}"
                                    (click)="nextOption(2)"
                                    class="btn btn-warning text-h3 text-white w-auto">
                                    Siguiente 
                                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </accordion-group>
                <accordion-group
                    panelClass="b0 mb-2"
                    class="shadow"
                    #methodOfPayment
                    isAnimated="true">
                    <div accordion-heading>
                        <span class="card-title text-h1">
                            <strong> {{userRol == 'Ventas telemercadeo' ? '3.' : '2.'}} Método de pago </strong>
                        </span>
                    </div>
                    <div>
                        <div
                            class="row px-4"
                            *ngIf="userLogged && userRol == 'Individual'"
                            [formGroup]="checkoutForm">
                            <div class="col-6">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        id="card"
                                        type="radio"
                                        name="paymenthMethod"
                                        value="card"
                                        formControlName="paymenthMethod"
                                        (change)="
                                            changeMethodPayment('card')
                                        " />
                                    <label
                                        class="form-check-label text-h4 mt-1 mb-0"
                                        for="card">
                                        Tarjeta
                                    </label>
                                    <br />
                                    <label class="text-referencia">
                                        Pago con tarjeta
                                    </label>
                                </div>
                                <label
                                    [ngClass]="{
                                        'text-muted':
                                            getPaymenthMethod.value != 'card'
                                    }"
                                    class="cursor-pointer text-h3"
                                    (click)="onClickAddCard()">
                                    <i class="bi bi-plus-circle"></i>
                                    Agregar tarjeta
                                </label>
                            </div>
                            <div
                                class="col-12"
                                *ngIf="getPaymenthMethod.value == 'card' && !this.showPayBtn()">
                                <app-select
                                    #selectCard
                                    (formReady)="addForm('cardsCredit', $event)"
                                    label="Tarjeta de Crédito"
                                    [list]="listCard"
                                    title="Tarjetas"
                                    [required]="true"
                                    (changeSelected)="selectChangeCard($event)"
                                    (onClickIcon)="
                                        showModalEditCard($event)
                                    "></app-select>
                            </div>
                        </div>
                        <div class="row px-4" *ngIf="!userLogged">
                            <div class="col-12">
                                <app-form-card-management
                                    [isModal]="false"
                                    [showFinish]="showPayBtn()"
                                    (sendCardAdded)="cardAdded($event)">
                                </app-form-card-management>
                            </div>
                        </div>
                        <div
                            class="row px-4"
                            *ngIf="userLogged && userRol != 'Individual'"
                            [formGroup]="telemarketingPaymentForm">
                            <div class="col-12">
                                <app-input
                                    title="Número de autorización"
                                    [required]="true"
                                    [validators]="validatorsCustom.referenceNumber"
                                    [minLength]="5"
                                    [maxLength]="10"
                                    (formReady)="
                                        addForm('referenceNumber', $event)
                                    " 
                                    [regex]="regexAnyNumber"></app-input>
                            </div>
                            <div class="col-12">
                                <label>
                                    <span class="text-dark">
                                        Comprobante de pago
                                    </span>
                                    <span class="text-danger h5">
                                        <Strong>*</Strong>
                                    </span>
                                    <span class="text-referencia">
                                        (Max. 1MB)
                                    </span>
                                </label>
                                <div>
                                    <image-cropper
                                        class="btn"
                                        *ngIf="srcImg"
                                        [cropperMinWidth]="250"
                                        [cropperMinHeight]="250"
                                        [roundCropper]="false"
                                        [imageBase64]="srcImg"
                                        [maintainAspectRatio]="false"
                                        [aspectRatio]="1 / 1"
                                        [resizeToWidth]="250"
                                        [resizeToHeight]="250"
                                        (imageCropped)="
                                            imageCropped($event)
                                        "></image-cropper>
                                </div>
                                <div
                                    *ngIf="!srcImg"
                                    ng2FileDrop
                                    class="noImage border rounded btn hwa-uploadAnimated"
                                    (fileOver)="fileOverBase($event)"
                                    [uploader]="uploader"
                                    [ngClass]="{
                                        'containerIMG-drop': hasBaseDropZoneOver
                                    }"
                                    (click)="uploadImage(inputFileImage)"
                                    style="z-index: 1"></div>
                                <input
                                    [disabled]="showPayBtn()" 
                                    ng2FileSelect
                                    (change)="changeInputFile()"
                                    [uploader]="uploader"
                                    type="file"
                                    style="display: none"
                                    #inputFileImage
                                    accept="image/png, image/jpeg" />
                                <button
                                    [disabled]="showPayBtn()"
                                    class="btn btn-warning w-100 mt-2"
                                    (click)="uploadImage(inputFileImage)">
                                    <i class="bi bi-upload"></i>
                                    Seleccionar imagen
                                </button>
                            </div>
                        </div>
                        <div class="w-100 mt-3 d-flex justify-content-end pr-4" *ngIf="userRol != 'Individual'">
                            <div class="w-auto">
                                <button
                                    [disabled]="disabledBtnNext(2)"
                                    [ngClass]="{'btn-disabled-gray': disabledBtnNext(2)}"
                                    (click)="nextOption(3)"
                                    class="btn btn-warning text-h3 text-white w-auto">
                                    Siguiente 
                                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </accordion-group>
                <accordion-group
                    panelClass="b0 mb-2"
                    class="shadow"
                    #serviceDetail
                    isAnimated="true">
                    <div accordion-heading>
                        <span class="card-title text-h1">
                            <strong> {{userRol == 'Ventas telemercadeo' ? '4.' : '3.'}} Detalle de compra </strong>
                        </span>
                    </div>
                    <div>
                        <div class="row">
                            <div class="col-12">
                                <app-detail-cart
                                    *ngIf="showAppDetail"
                                    #detailCart></app-detail-cart>
                            </div>
                        </div>
                    </div>
                </accordion-group>
            </accordion>
        </div>
    </div>
</div>
<app-list-modal
    #listBillingElement
    [list]="listBilling"
    (changeSelected)="changeSelectBilling($event)"
    (closeModal)="closeModalBillingFav()"></app-list-modal>
<app-modal #createBillingForm [idModal]="'createBillingForm'">
    <app-crear-info-fact
        [customerSelected]="customerSelect"
        [isModal]="true"
        [closeModal]="onCloseModal"></app-crear-info-fact>
</app-modal>
<app-modal
    origin="clubForza"
    [title]="getTitleConfirmPayment()"
    #confirmPaymentModal
    [idModal]="'confirmPaymentModal'"
    [paymentIsConfirmed]="paymentIsConfirmed"
    (paymentCompleted)="paymentCompleted($event)"
    [paymentIsCompleted]="paymentIsCompleted"
    [orderNumber]="orderNumber">
    <app-confirm-payment
        (sendStatustransaction)="paymentCompleted($event)"
        (sendValidationStatustransaction)="validStatusPayment($event)"
        [orderNumber]="orderNumber"
        origin="clubForza"
        #confirmPaymentComponent
        [onCloseModal]="closeModalEvent"
        [html]="htmlToPay"
        [data]="dataToConfirm"
        (confirmPayment)="confirmPayment($event)"></app-confirm-payment>
        <div class="row d-flex justify-content-center mt-4 mb-4">
            <div class="col-auto">
                <label>
                    Ver
                    <a (click)="redirectTo()" class="cursor-pointer" style="color: #5d9cec;">
                        Términos y condiciones
                    </a>
                </label>
            </div>
        </div>
</app-modal>
<app-modal #createFormCards [idModal]="'createFormCards'">
    <div class="card pb-4 pt-4">
        <app-form-card-management
            (onSaveCard)="changeCards($event)"></app-form-card-management>
    </div>
</app-modal>
<app-modal
    *ngIf="listCardEdit"
    #modalEditCard
    size="sm"
    title="{{ title }}"
    idModal="modalEditCard">
    <app-list-card-management
        (selectedCard)="updateListCard($event)"
        [listCard]="listCardEdit"
        [isModal]="true"
        [deleteDefault]="deleteDefault">
    </app-list-card-management>
</app-modal>
<app-modal
    #modalTerminos
    title="Términos y condiciones"
    idModal="terminosCondiciones">
    <app-terms-and-conditions [isModal]="true" [products]="detailCart?.listProductsOfCart"></app-terms-and-conditions>
    <div class="W-100 d-flex justify-content-center align-items-center">
        <button
        class="btn btn-warning text-h3 text-white w-auto pay-cart"
        (click)="modalTerminos.Close()">
        Aceptar
    </button>
    </div>
</app-modal>
